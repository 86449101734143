<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import type { AssetData } from '@/types/asset'

const props = defineProps<{
  assets: AssetData[]
}>()

const isGalleryModalLoaded = ref(false)
const isGalleryModalVisible = ref(false)

const isAssetModalOpen = ref(false)
const assetToDisplay = ref<AssetData | undefined>()

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.greater('lg')
const isShowAllButtonVisible = computed(
  () =>
    (!isDesktop.value && props.assets.length > 2) || props.assets.length > 4,
)

const isVideo = (asset: AssetData) => !!asset.video

const showGalleryModal = () => {
  isGalleryModalLoaded.value = true
  isGalleryModalVisible.value = true
}

const openAsset = (assetData: AssetData) => {
  isAssetModalOpen.value = true
  assetToDisplay.value = assetData
}

// Fix - First level modal is losing mask, when nested modal closed.
const restoreDismissableMask = () => {
  if (isGalleryModalVisible.value) {
    document.body.classList.add('p-overflow-hidden')
  }
}
</script>

<template>
  <div class="relative">
    <Button
      v-show="isShowAllButtonVisible"
      label="Show all"
      icon="pi pi-table"
      icon-class="text-blue-200 group-hover:text-white transition ease-in-out"
      class="p-button-small p-button-primary group absolute bottom-2 right-2 z-10 border-gray-400 bg-white text-blue-100 hover:text-white"
      @click="showGalleryModal"
    />
    <LazyGigDetailsGalleryModal
      v-if="isGalleryModalLoaded"
      v-model:visible="isGalleryModalVisible"
      :assets="assets"
      @close="isGalleryModalVisible = false"
      @open-asset="(asset: AssetData) => openAsset(asset)"
    />
    <LazyGigDetailsGalleryAssetModal
      v-if="assetToDisplay"
      v-model:visible="isAssetModalOpen"
      :asset="assetToDisplay"
      :is-video="isVideo(assetToDisplay)"
      @after-hide="restoreDismissableMask"
    >
      <GigDetailsGalleryModalVideoContent
        v-if="isVideo(assetToDisplay)"
        :video-asset="assetToDisplay"
        @close="isAssetModalOpen = false"
      />
      <GigDetailsGalleryModalImageContent
        v-else
        :asset="assetToDisplay"
        @close="isAssetModalOpen = false"
      />
    </LazyGigDetailsGalleryAssetModal>

    <AppSlider
      prev-button-class="md:hidden"
      next-button-class="md:hidden"
      wrapper-class="lg:grid lg:h-[480px] lg:gap-4 lg:w-full lg:grid-rows-2 lg:items-center lg:justify-center lg:grid-cols-[280px_1fr_340px]"
      :class="['mb-8 lg:h-[480px] ', { 'bg-gray-600': assets.length === 0 }]"
    >
      <template
        v-for="(asset, index) in assets"
        :key="asset.id"
      >
        <div
          v-if="index === 0"
          class="slides-item relative flex shrink-0 snap-start overflow-hidden rounded-md md:col-start-1 md:col-end-2 md:row-start-1 md:row-end-3 md:h-full md:w-full"
        >
          <GigDetailsGalleryImage
            :src="asset?.image || asset?.thumbnail"
            :is-video="isVideo(asset)"
            :alt="asset.name?.['en-US']"
            @open="openAsset(asset)"
          />
        </div>

        <div
          v-if="index === 1"
          class="slides-item relative flex shrink-0 snap-start overflow-hidden rounded-md md:col-start-2 md:col-end-3 md:row-start-1 md:row-end-3 md:h-full md:w-full"
        >
          <GigDetailsGalleryImage
            :src="asset?.image || asset?.thumbnail"
            :is-video="isVideo(asset)"
            :alt="asset.name?.['en-US']"
            @open="openAsset(asset)"
          />
        </div>
        <div
          v-if="index === 2"
          class="slides-item relative w-auto shrink-0 snap-start overflow-hidden rounded-md md:col-start-3 md:col-end-4 md:row-start-1 md:row-end-2 md:h-full md:w-full"
        >
          <GigDetailsGalleryImage
            :src="asset?.image || asset?.thumbnail"
            :is-video="isVideo(asset)"
            :alt="asset.name?.['en-US']"
            @open="openAsset(asset)"
          />
        </div>
        <div
          v-if="index === 3"
          class="slides-item relative w-auto shrink-0 snap-start overflow-hidden rounded-md md:col-start-3 md:col-end-4 md:row-start-2 md:row-end-3 md:h-full md:w-full"
        >
          <GigDetailsGalleryImage
            :src="asset?.image || asset?.thumbnail"
            :is-video="isVideo(asset)"
            :alt="asset.name?.['en-US']"
            @open="openAsset(asset)"
          />
        </div>
      </template>
    </AppSlider>
  </div>
</template>

<style scoped>
@media (max-width: 1024px) {
  :deep(.slides) {
    --gap: 16px;
    gap: var(--gap);
  }

  .slides-item {
    --slides-number: 2;
    --visible-slides: 1.15;
    --spacing-per-slide: calc(var(--gap) * (var(--slides-number) - 1)) /
      var(--slides-number);
    flex-basis: calc((100% / var(--visible-slides)) - var(--spacing-per-slide));
    flex-grow: 0;
    flex-shrink: 0;

    @media (min-width: 400px) {
      --slides-number: 2;
      --visible-slides: 1.5;
    }

    @media (min-width: 640px) {
      --slides-number: 3;
      --visible-slides: 2.5;
    }
  }
}
</style>
