<script setup lang="ts">
const props = defineProps<{
  label: string
  visibilityLimit: number
  items: string[]
}>()

const showAll = ref(false)
const visibleItems = computed(() =>
  showAll.value ? props.items : props.items.slice(0, props.visibilityLimit),
)

const toggleItems = () => {
  showAll.value = !showAll.value
}
</script>

<template>
  <div class="flex flex-col">
    <div class="mb-2 text-base">{{ label }}</div>
    <div class="mr-8 flex flex-wrap gap-2">
      <GigDetailsTag
        v-for="(item, index) of visibleItems"
        :key="index"
        :label="item"
      />
      <GigDetailsTag
        v-if="items.length > visibilityLimit"
        tag="button"
        :aria-label="showAll ? `Show less ${label}` : `Show more ${label}`"
        :label="`${showAll ? '-' : '+'}${items.length - visibilityLimit}`"
        @click="toggleItems"
      />
    </div>
  </div>
</template>
