<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { EContent } from 'auth/enums/dialog-contents'

import type { Social } from 'profile/types/profile'
import type { AddToCartOptions } from 'checkout/types/add-to-cart-options'
import type { Reviews } from '@/types/reviews'
import type { BreadcrumbsItem } from '@/types/breadcrumbs-item'
import type { PrimevueButton } from '@/types/primevue-button'

const { $notify, $priceNumber } = useNuxtApp()

const authStore = useAuthStore()
const { isCreator } = storeToRefs(useCustomerStore())
const { isDialogVisible, isLoggedIn } = storeToRefs(authStore)
const { profileId: loggedInCreatorId } = useCreatorStore()

const menuItems = [
  {
    id: 'message',
    label: 'Message Creator',
    isMdHidden: false,
    command: () => {
      messageCreator()
    },
  },
  {
    id: 'request',
    label: 'Request Custom Quote',
    isMdHidden: false,
    command: () => {
      messageCreator('true')
    },
  },
]

const route = useRoute()

const params = {
  expand: 'masterVariant.attributes[*].value',
  where: `slug(en-US="${route.params.slug}") and productType(id=:gig)`,
}
const gig = await useGig(params)
const profilePicture = useProfilePicture(gig.creator?.owner?.id)

const brands =
  gig.creator.assets?.filter((asset) => {
    return gig.brandIds?.find((brandId) => asset.id === brandId)
  }) || []

const sortedContent = computed(() => gig.contentIsAbout?.sort())

useHead({
  title: `${gig.creator.name} - ${gig.name}`,
  meta: [
    {
      name: 'description',
      content: `Branded Content - ${gig.name}`,
    },
  ],
})

let reviews: Reviews | null = null
if (gig.reviewRatingStatistics?.count) {
  reviews = await useReviews(gig.id)
}

const breadcrumbHome: BreadcrumbsItem = {
  label: 'Marketplace',
  to: '/search/all',
}
const breadcrumbItems: BreadcrumbsItem[] = gig.name ? [{ label: gig.name }] : []
const shareModalDisplay = ref(false)

const addToCart = async (options: AddToCartOptions) => {
  if (gig.creator.id === loggedInCreatorId) {
    $notify({
      severity: 'error',
      text: 'You cannot buy your own Collab.',
    })
    return false
  }
  const { addItem } = useCartStore()

  await addItem({
    productId: gig.id,
    variantId: options.selectedPackageId,
    extraDelivery: options.extraDelivery,
    postOnSocial: options.postOnSocial,
  })
}

const messageCreator = async (isRequestCustomQuoteModalVisible?: string) => {
  if (!isLoggedIn.value) {
    isDialogVisible.value = true
    authStore.switchContent(EContent.SIGN_UP_FIRST_BRAND)
    navigateTo({
      query: {
        redirect: `/account/messages?gigId=${gig.id}&gigType=gig`,
      },
    })
    return false
  }
  const conversationId = await useFetchChatConversationId(
    isRequestCustomQuoteModalVisible ? gig.creator.id : gig.id,
    'gig',
  )

  if (conversationId) {
    await navigateTo({
      path: `/account/messages/${conversationId}`,
      query: {
        isRequestCustomQuoteModalVisible,
      },
    })
  }
}

const openShareModal = () => {
  shareModalDisplay.value = true
}

const customerStore = useCustomerStore()
const { customer } = storeToRefs(customerStore)

const getCustomerFullName = () => {
  const firstName = customer.value?.firstName || ''
  const lastName = customer.value?.lastName || ''

  return `${firstName} ${lastName}`.trim()
}

const favoritesStore = useFavoritesStore()
const { gigIds, creatorIds } = storeToRefs(favoritesStore)

const updateFavoriteGig = async (id: string) => {
  await favoritesStore.updateFavorite(id, 'gig')
}

const updateFavoriteCreator = async (id: string) => {
  await favoritesStore.updateFavorite(id, 'profile')
}

const logCollabAbandon = (redirectUrl = '') => {
  if (!redirectUrl.startsWith('/checkout') && isLoggedIn.value) {
    useKlaviyo('collab_abandon', {
      collab: gig,
      redirectUrl,
    })
  }
}

const socialsRows = computed(() => {
  const rows: Social[][] = []
  const socials = gig.creator?.socials
  while (socials.length > 0) {
    rows.push(socials.splice(0, 3))
  }
  return rows
})

onBeforeRouteLeave(({ fullPath }) => {
  logCollabAbandon(fullPath)
})

const dropdownChatTriggerRef = ref<PrimevueButton | null>(null)
const buttonChatTriggerEl = ref<HTMLButtonElement | null | undefined>(null)

// pass template ref to child component
onMounted(() => {
  if (dropdownChatTriggerRef.value) {
    buttonChatTriggerEl.value = dropdownChatTriggerRef.value.$el
  }
})

onMounted(() => {
  const collabPrice = $priceNumber(gig.packages[0].price)

  if (isLoggedIn.value) {
    useKlaviyo('Viewed Product', {
      ProductName: gig.name,
      ProductID: gig.id,
      SKU: gig.slug,
      Categories: gig.contentType,
      ImageURL: gig.assets[0].image,
      URL: `${window.location.origin}${route.fullPath}`,
      Brand: gig.creator.name,
      Price: collabPrice,
      CompareAtPrice: collabPrice,
    })
  }

  window.addEventListener('beforeunload', () => {
    logCollabAbandon()
  })
})
</script>

<template>
  <div>
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="mb-11 mt-7 hidden lg:block [&>ol]:flex [&>ol]:items-center"
    />
    <h1 class="mb-3 mt-4 text-2xl lg:mb-2 lg:mt-0 lg:text-4xl">
      {{ gig.name }}
    </h1>
    <div class="mb-4 flex justify-between lg:items-center">
      <GigDetailsCreatorSummary
        :name="gig.creator.name"
        :avatar="profilePicture"
        :rating="gig.creator.reviewRatingStatistics?.averageRating.toFixed(1)"
        :rating-count="gig.creator.reviewRatingStatistics?.count"
        :projects-in-queue="gig.creator.projectsInQueue"
        :response-time="gig.creator.responseTime"
        :profile-url="`/profile/${gig.creator.slug}`"
      />
      <div class="mt-1 lg:mt-0">
        <Button
          label="Share"
          icon="pi pi-share-alt"
          class="p-button-small p-button-secondary p-button-text mr-5"
          aria-haspopup="dialog"
          :aria-expanded="shareModalDisplay"
          @click="openShareModal"
        />
        <GigDetailsShareModal
          v-model:visible="shareModalDisplay"
          :email-subject="gig.name"
          :gig-name="gig.name"
          :user-name="getCustomerFullName()"
        />
        <Button
          :icon="`pi ${gigIds.includes(gig.id) ? 'pi-heart-fill' : 'pi-heart'}`"
          aria-label="add gig to favorites"
          class="p-button-favorite p-button-rounded p-button-outlined"
          @click="updateFavoriteGig(gig.id)"
        />
      </div>
    </div>

    <GigDetailsGallery
      v-if="gig.assets.length > 0"
      :assets="gig.assets"
    />

    <BaseTopClientCarousel
      :assets="brands"
      class="my-8 md:mt-4 md:flex-row"
      slider-class="py-2 md:w-5/6 md:px-4"
      wrapper-class="before:left-[54px]"
    >
      <template #heading>
        <div
          class="whitespace-normal break-words pl-4 pt-4 text-base font-medium text-gray-100 md:flex md:w-1/6 md:items-center md:px-4 md:py-4"
        >
          <span class="block w-full md:text-center">My Top Clients</span>
        </div>
      </template>
    </BaseTopClientCarousel>

    <div class="flex flex-col items-start justify-between pb-14 lg:flex-row">
      <div class="order-2 w-full min-w-0 flex-1 lg:order-1 lg:pr-28">
        <template v-if="gig.description">
          <h2 class="text-2xl">Description</h2>
          <div
            class="gig-description my-8 break-words leading-5"
            v-html="gig.description"
          />
        </template>

        <div
          class="mb-8 flex flex-col border-b border-gray-500 pb-8 md:flex-row"
        >
          <GigDetailsContentType
            v-if="gig.contentType"
            :visibility-limit="4"
            class="mb-8 md:mb-0 md:w-1/2"
            label="Content Type"
            :items="gig.contentType"
          />
          <GigDetailsContentType
            v-if="gig.contentIsAbout.length"
            :visibility-limit="5"
            class="md:w-1/2 md:border-l md:border-gray-400 md:pl-8"
            label="My social channels are about"
            :items="sortedContent"
          />
        </div>
        <div class="flex justify-between gap-x-2 md:items-center">
          <div class="flex flex-1 md:items-center">
            <div class="shrink-0">
              <BaseAvatar
                size="large"
                :user-name="gig.creator.name"
                :src="profilePicture"
              />
            </div>
            <div class="ml-2 md:flex md:flex-1 md:justify-between">
              <div>
                <NuxtLink
                  class="pb-1 text-2xl"
                  :to="`/profile/${gig.creator.slug}`"
                >
                  {{ gig.creator.name }}
                </NuxtLink>

                <div class="text-gray-200">
                  {{ gig.creator.state ? `${gig.creator.state}, ` : ''
                  }}{{ gig.creator.country }} - Creator since
                  {{ gig.creator.createdAt }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <span aria-hidden="true">Add to favorites</span>
            <Button
              :icon="`pi ${
                creatorIds.includes(gig.creator.id)
                  ? 'pi-heart-fill'
                  : 'pi-heart'
              }`"
              class="p-button-favorite p-button-rounded p-button-outlined shrink-0 grow-0"
              aria-label="Add Creator to favorites"
              @click="updateFavoriteCreator(gig.creator.id)"
            />
          </div>
        </div>

        <div
          v-if="socialsRows?.length"
          class="mt-8 divide-y divide-gray-500 py-4 sm:divide-y-0 xl:pr-24"
        >
          <ul
            v-for="(socials, key) in socialsRows"
            :key="key"
            class="flex flex-col divide-y divide-gray-500 text-base sm:flex-row sm:divide-x sm:divide-y-0"
          >
            <template
              v-for="social in socials"
              :key="social.id"
            >
              <SocialMetrics
                v-if="social.id"
                class="relative !px-4 sm:my-3 sm:w-1/3"
                :class="socialsRows?.length > 1 ? 'custom-divider' : ''"
                :social="social"
              />
            </template>
          </ul>
        </div>

        <div class="my-6 break-words leading-5">
          {{ gig.creator.description }}
        </div>
      </div>
      <div class="order-1 mb-8 w-full lg:order-2 lg:mb-0 lg:w-[370px]">
        <BaseCard v-if="gig.packages?.length">
          <GigDetailsAddToCartSection
            :packages="gig.packages"
            @add-to-cart="addToCart"
          />
        </BaseCard>
        <div
          v-if="gig.creator.id !== loggedInCreatorId && !isCreator"
          class="mt-8 rounded-md bg-gray-600 p-8"
        >
          <BaseMenu
            :items="menuItems"
            dropdown-pos="right"
            dropdown-classes="focus-visible:!outline-white"
            trigger-label="Toggle Menu"
            :trigger-el="buttonChatTriggerEl"
            type="boxed"
            class="min-w-[78px]"
          >
            <template
              #default="{ isMenuOpen, toggleMenu, label, closeMenu, passFocus }"
            >
              <Button
                ref="dropdownChatTriggerRef"
                class="p-button-rounded align-center group h-[54px] w-full justify-center border-gray-400 bg-white text-blue-100"
                aria-haspopup="menu"
                :aria-expanded="isMenuOpen"
                :label="label"
                :disabled="!dropdownChatTriggerRef"
                @click="toggleMenu"
                @keydown.escape="closeMenu"
                @keydown.tab="passFocus"
              >
                <span class="mr-2">Contact Creator</span>
                <i
                  v-if="isMenuOpen"
                  class="pi pi-angle-up"
                />
                <i
                  v-else
                  class="pi pi-angle-down"
                />
              </Button>
            </template>
          </BaseMenu>
        </div>
      </div>
    </div>
    <GigReviewHeader
      v-if="gig.reviewRatingStatistics?.count"
      :review-count="gig.reviewRatingStatistics.count"
      :review-rating="gig.reviewRatingStatistics.averageRating"
      class="border-t border-gray-500 pt-9"
    />
    <div
      v-if="gig.reviewRatingStatistics?.count && reviews"
      class="mb-12 mt-8 grid gap-11 lg:mb-16 lg:grid-cols-2 lg:gap-16"
    >
      <GigReviewItem
        v-for="review in reviews.results"
        :key="review.id"
        :client-id="review.customer.id"
        :name="review.authorName"
        :rating="review.rating"
        :date="new Date(review.createdAt)"
        :content="review.text"
      />
    </div>
  </div>
</template>

<style scoped>
:deep(.pi-share-alt) {
  @apply text-gray-100;
}

:deep(.p-button-text.p-button-secondary .p-button-label) {
  @apply text-gray-200;
  @apply font-normal;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.custom-divider::after {
  @apply absolute top-[calc(100%+12px)] block h-[1px] w-[calc(100%+1px)] bg-gray-500 sm:content-[''];
}

/* For Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

<style>
.gig-description ul {
  @apply list-disc pl-3.5;
}

.gig-description ol {
  @apply list-decimal pl-3.5;
}
</style>
