<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    label: string
    tag?: string
  }>(),
  {
    tag: 'div',
  },
)

const type = computed(() => (props.tag === 'button' ? 'button' : undefined))
</script>

<template>
  <Component
    :is="tag"
    :type="type"
    :class="[
      'inline-flex rounded-md bg-gray-600 p-2.5 text-sm text-gray-200',
      {
        'cursor-pointer hover:opacity-80': tag === 'button',
      },
    ]"
  >
    {{ label }}
  </Component>
</template>
