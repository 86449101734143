<script setup lang="ts">
export interface ShareDialogProps {
  emailSubject: string
  userName?: string
  gigName: string
}

const props = defineProps<ShareDialogProps>()

const isEmailSent = ref(false)
const { copy, copied } = useClipboard()

const sendEmail = () => {
  const loggedInHeader = `${props.userName} wants you to check out the Branded Content Collab below:%0D%0A`
  const notLoggedInHeader = 'Check out the Branded Content Collab below:%0D%0A'
  const emailBody = `
    ${props.userName ? loggedInHeader : notLoggedInHeader}
    %0D%0A
    ${props.gigName}%0D%0A
    ${window.location.href}%0D%0A
    %0D%0A
    Sincerely, The Branded Content Team
  `
  window.open(`mailto:?subject=${props.emailSubject}&body=${emailBody}`)

  isEmailSent.value = true
}

const copyLink = () => {
  copy(window.location.href)
}

const handleAfterHide = () => {
  isEmailSent.value = false
}
</script>

<template>
  <Dialog
    modal
    dismissable-mask
    :draggable="false"
    class="w-full max-w-[390px]"
    content-class="flex flex-col items-start"
    @after-hide="handleAfterHide"
  >
    <h2 class="mb-10 inline-flex pt-2 text-2xl">Share Collab</h2>
    <div class="grid w-full grid-cols-2 gap-x-3 pb-4">
      <Button
        label="Send by E-mail"
        icon="pi pi-envelope"
        icon-class="text-2xl"
        class="p-button-text p-3 text-base text-gray-200"
        @click="sendEmail"
      />
      <div class="flex items-center justify-start">
        <span
          v-if="isEmailSent"
          class="whitespace-nowrap rounded-md bg-blue-600 p-4 text-base text-blue-100"
        >
          E-mail sent!
        </span>
      </div>
      <Button
        label="Copy URL"
        icon="pi pi-link"
        icon-class="text-2xl text-gray-100"
        class="p-button-text p-3 text-base text-gray-200"
        @click="copyLink"
      />
      <Transition name="fade">
        <div
          v-if="copied"
          class="flex items-center justify-start"
          aria-live="assertive"
        >
          <span
            class="whitespace-nowrap rounded-md bg-blue-600 p-4 text-base text-blue-100"
          >
            URL copied!
          </span>
        </div>
      </Transition>
    </div>
  </Dialog>
</template>

<style scoped>
:deep(.p-button .p-button-label) {
  @apply flex-grow-0 font-normal text-gray-200;
}

:deep(.p-button .p-button-icon) {
  @apply text-gray-100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
